@import "src/styles/variables";

.product-form-content-left-header {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .mg-t-12{
        margin-top: -16px;
    }
    &__inputs {
        display: flex;
        gap: 1.2rem;

        &__sustainability {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }
    }
}
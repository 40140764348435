@import "src/styles/variables";

.make-and-model {
  .wzmh-collapse-content-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.6rem;
    margin-top: 2.4rem;

    .make-and-model__value {
      font-size: 1.5rem;
    }
  }
}

@import "src/styles/variables";

.sustainable-card {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: hidden;
    padding-top: 2rem;
    background-color: $sustainable;

    &__title {
        align-items: center;
        color: $sustainable-product;
        display: flex;
        font-family: "AvenirHeavy";
        font-size: 1.8rem;
        gap: 1rem;
    }

    &__description {
        margin-bottom: 0.8rem;
    }

    &__title,
    &__description {
        padding-inline: 2rem 2.2rem;
    }

    &__image {
        width: 100%;
    }
}
@import "src/styles/variables";

.view-product-content {
    display: grid;
    grid-template-columns: 58rem auto;
    column-gap: 4.8rem;

    &__start {
        align-self: flex-start;
        border-radius: 16px;
        border: 1px solid $grey-5;
        overflow: hidden;

        display: block;
        position: relative;

        width: 58rem;
        aspect-ratio: 1;

        .wzmh-skeleton.wzmh-skeleton-element .wzmh-skeleton-image.product-img-skeleton {
            width: 58rem;
            height: 58rem;
        }
    }

    &__image {
        width: 100%;
        aspect-ratio: 1;
        align-self: flex-start;

        &-overlay {
            color: $white;
            fill: $white;
            cursor: pointer;
            box-sizing: border-box;
            height: 4rem;
            border-radius: 10px;
            background: $primary-color;
            display: flex;
            overflow: hidden;

            justify-content: space-around;
            align-items: flex-start;

            position: absolute;
            bottom: 2rem;
            left: 2rem;

            padding: 1rem;
            transition: all ease-in-out 0.3s;

            svg {
                height: 2rem;
            }

            &_text {
                height: 1.8rem;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 0;
                display: inline-block;
                padding-left: 0;
                transition: width ease-in-out 0.3s, opacity ease-in-out 0.3s 0.1s, padding ease-in-out 0.3s;
                opacity: 0;
            }

            &:hover {
                .view-product-content__image-overlay_text {
                    padding-left: 0.5rem;
                    opacity: 1;
                    width: 9.5rem;
                }
            }
        }

        object-fit: contain;
    }

    &__end {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .status {
            align-self: flex-start;
        }

        &__title {
            margin-block-end: 3.2rem;
            font-size: 2.4rem;
        }

        .list-variant {
            margin-bottom: 2.4rem;
        }

        &__cost {
            text-transform: uppercase;
            margin-block: 2rem 1.3rem;
            color: $blue;
            font-size: 2.4rem;
        }
    }

    .product-description {
        .wzmh-collapse-content-box {
            white-space: pre-line;
        }
    }
}
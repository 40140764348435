@import "src/styles/variables";

.layer-variant-details {
  padding: 3.2rem 3.2rem 0 4rem;
  position: relative;
  width: 90%;
  background-color: $white;

  .product-name {
    font-size: 2rem;
    font-family: "AvenirBlack";
    color: $grey;
    margin-top: 3.2rem;
  }

  .view-product-content__start {
    width: 30rem;
  }

  .view-product-content__image {
    height: 30rem;
    width: 30rem;
  }

  .width-100 {
    width: 60vw;
  }

  &__action {
    & > div {
      max-width: 100%;
    }

    &s {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 100%;
    }

    border-radius: 8px;
    border: 1px solid $grey-4;
    height: 3.2rem;
    width: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.4rem;
    cursor: pointer;

    .icon {
      height: 1.2rem;
      width: 1.2rem;
    }

    svg {
      fill: $grey-2 !important;
    }

    &:hover {
      border-color: $primary-light-60;

      svg {
        fill: $primary-color !important;
      }
    }
  }

  .variant-details {
    margin-top: 3.2rem;

    &__card {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      gap: 2.4rem;
      margin-bottom: 3.2rem;
    }
  }
}

@import "src/styles/variables";

.product-form-content-right {
  flex-grow: 1;

  .icon-container {
    gap: 0.8rem;
  }

  .dropdown-field {
    flex-grow: 1;
  }

  .wzmh-select-selector {
    padding: 0;
  }
  .grouped-error {
    margin-top: 12px;
  }

  .input-field {
    flex-grow: 1;
  }

  .wzmh-space {
    width: 100% !important;
  }
}

@import "src/styles/variables";

.variant-details {
    &__title {
        font-size: 1.8rem;
        margin-block-end: 2.4rem;
    }

    &__card {
        display: flex;
        justify-content: space-between;
        background-color: rgba($color: $primary-color, $alpha: 0.05);
        padding: 2rem;
        border-radius: 8px;
        margin-bottom: 3.2rem;
    }
}
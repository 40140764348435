@import "src/styles/variables";

.product-form-content {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;

    &__main {
        display: flex;
        gap: 3.2rem;
    }

    &__variants,
    &__attachments {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        &__title {
            font-size: 1.8rem;
            font-family: "AvenirHeavy";
        }
    }


    .switch__field {
        padding-inline: 0;
    }
}
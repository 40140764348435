@import "src/styles/variables";

.dimension-and-cost {
    .wzmh-collapse-content-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1.6rem;
        margin-top: 2.4rem;

        .dimension-and-cost__value {
            font-size: 1.5rem;

            &__dropdown {
                display: flex;
                align-items: center;

                .wzmh-select-arrow {
                    svg {
                        fill: $primary-color;
                        width: 0.6rem;
                        height: 0.4rem;
                    }
                }
            }
        }
    }
}